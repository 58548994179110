@import "../node_modules/modern-normalize/modern-normalize.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--white);
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
html,
body {
  box-sizing: border-box;
  font-size: 0.833vw;
  font-family: gilroy;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  height: 100dvh;
  overflow-x: hidden;
  color: var(--dark);
  scroll-behavior: smooth;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
  border: none;
  outline: none;
}
img,
video {
  /* max-width: 100%; */
  height: auto;
}
::-webkit-scrollbar {
  width: 0.5em;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: var(--main);
  border-radius: 0.2em;
  transition: all 2s ease-in-out;
}
.swiper-backface-hidden .swiper-slide {
  width: fit-content;
}
@font-face {
  font-family: "gilroy";
  font-weight: 400;
  src: local("gilroy"),
    url(./fonts/gilroy/Gilroy-Regular-400.ttf) format("opentype");
}
@font-face {
  font-family: "gilroy";
  font-weight: 500;
  src: local("gilroy"),
    url(./fonts/gilroy/Gilroy-Medium-500.ttf) format("opentype");
}
@font-face {
  font-family: "gilroy";
  font-weight: 600;
  src: local("gilroy"),
    url(./fonts/gilroy/Gilroy-SemiBold-600.ttf) format("opentype");
}
@font-face {
  font-family: "gilroy";
  font-weight: 800;
  src: local("gilroy"),
    url(./fonts/gilroy/Gilroy-ExtraBold-800.ttf) format("opentype");
}

/* @font-face {
  font-family: "Vela Sans";
  font-weight: 500;
  src: local("Vela Sans"),
    url(./fonts/velaSans/VelaSans-Medium500.ttf) format("opentype");
}
@font-face {
  font-family: "Vela Sans";
  font-weight: 600;
  src: local("Vela Sans"),
    url(./fonts/velaSans/VelaSans-SemiBold600.ttf) format("opentype");
}
@font-face {
  font-family: "Vela Sans";
  font-weight: 800;
  src: local("Vela Sans"),
    url(./fonts/velaSans/VelaSans-ExtraBold800.ttf) format("opentype");
}
@font-face {
  font-family: "RedHat";
  font-weight: 400;
  src: local("RedHat"),
    url(./fonts/redHatDisplay/RedHatDisplay-Regular400.ttf) format("opentype");
}
@font-face {
  font-family: "RedHat";
  font-weight: 500;
  src: local("RedHat"),
    url(./fonts/redHatDisplay/RedHatDisplay-Medium500.ttf) format("opentype");
} */
@media (orientation: portrait) {
  html,
  body {
    font-size: 2.2vw;
  }
}

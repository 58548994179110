.container {
  width: fit-content;
  /* display: inline-block; */
  text-wrap: nowrap;
  padding: 0.5em 1.111em;
  border-radius: 2.333em;
  border: 0.11em solid var(--grey);
  /* font-family: RedHat; */
  font-size: 1.125em;
  line-height: 133.333%;
  color: var(--grey);
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
}
.black {
  background-color: var(--dark);
  color: var(--white);
  border-color: var(--white);
  position: relative;
}
.active {
  background: var(--main);
  color: var(--dark);
  border-color: var(--main);
}
.sliderInActiv {
  color: var(--white);
  border-color: var(--white);
}
@media (orientation: portrait) {
  .container {
    font-size: 1.7em;
    padding: 0.75em 1.67em;
  }
  .changeFontSize {
    font-size: 2.27em;
    padding: 0.5625em 1.25em;
    border-width: 0.0625em;
  }
}

.navigation {
  display: flex;
  justify-content: space-between;
  margin: 3.4375em 0;
}
.list {
  /* display: flex; */
  flex-shrink: 1;
  width: 100%;
}
.item {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.casesWrapper {
  margin-left: 15em;
}
.casesTitle {
  margin-right: 11.5em;
}
@media (orientation: portrait) {
  .navigation {
    flex-direction: column;
  }
  .list {
    margin-top: 2.55em;
  }
  .casesWrapper {
    margin-left: 1.71em;
  }
}

@import url(../../vars.css);

.container {
  margin: 0 15em;
}

@media (orientation: portrait) {
  .container {
    margin: 0 1.71em;
  }
}

.container {
  padding-top: 3.375em;
  padding-bottom: 5.375em;
}
.wrapper {
  margin-bottom: 3.375em;
}
.list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
}
.item {
  position: relative;
  padding: 3.75em 3.75em 0 3.75em;
  height: 30em;
  border-radius: 2.8125em;
  border: 0.044em solid var(--dark);
  /* background: var(--white); */
  backdrop-filter: blur(0.5em);
  overflow: hidden;
  transition: background, color 0.3s ease-in-out;
}
/* .item:first-child {
  grid-area: 1 / 1 / 2 / 3;
}
.item:nth-child(2) {
  grid-area: 1 / 3 / 2 / 4;
}
.item:nth-child(3) {
  grid-area: 2 / 1 / 3 / 2;
}
.item:nth-child(4) {
  grid-area: 2 / 2 / 3 / 3;
}
.item:nth-child(5) {
  grid-area: 2 / 3 / 3 / 4;
}
.item:nth-child(6) {
  grid-area: 3 / 1 / 4 / 2;
}
.item:nth-child(7) {
  grid-area: 3 / 2 / 4 / 4;
} */
.list div:first-child {
  grid-area: 1 / 1 / 2 / 3;
}
.list div:nth-child(2) {
  grid-area: 1 / 3 / 2 / 4;
}
.list div:nth-child(3) {
  grid-area: 2 / 1 / 3 / 2;
}
.list div:nth-child(4) {
  grid-area: 2 / 2 / 3 / 3;
}
.list div:nth-child(5) {
  grid-area: 2 / 3 / 3 / 4;
}
.list div:nth-child(6) {
  grid-area: 3 / 1 / 4 / 2;
}
.list div:nth-child(7) {
  grid-area: 3 / 2 / 4 / 4;
}
.title {
  font-size: 3.375em;
  line-height: 133.333%;
}
.image {
  opacity: 0;
  display: none;
  position: absolute;
  width: 22.4375em;
  right: -5em;
  top: 12em;
  color: var(--main);
  fill: var(--main);
  transition: all 0.5s ease-in-out;
}

.item:hover .image,
.item:focus .image {
  opacity: 1;
  display: block;
  animation: rotateAnimHover 1.5s ease-in-out 1 running forwards;
}
.item:hover,
.item:focus {
  color: var(--main);
  background: var(--dark);
}
.icon {
  width: 10em;
  height: 10em;
}

@keyframes rotateAnimHover {
  0% {
    scale: 1.1;
  }
  100% {
    scale: 1;
  }
}
@media (orientation: portrait) {
  .container {
    padding-bottom: 7.67em;
  }
  .wrapper {
    margin-bottom: 4.545em;
  }
  .list {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 0.15em;
    grid-row-gap: 0.15em;
  }
  /* .item:first-child {
    grid-area: 1 / 1 / 2 / 2;
  }
  .item:nth-child(2) {
    grid-area: 1 / 2 / 2 / 3;
  }
  .item:nth-child(3) {
    grid-area: 2 / 1 / 3 / 2;
  }
  .item:nth-child(4) {
    grid-area: 2 / 2 / 3 / 3;
  }
  .item:nth-child(5) {
    grid-area: 3 / 1 / 4 / 2;
  }
  .item:nth-child(6) {
    grid-area: 3 / 2 / 4 / 3;
  }
  .item:nth-child(7) {
    grid-area: 4 / 1 / 5 / 3;
  } */
  .list div:first-child {
    grid-area: 1 / 1 / 2 / 2;
  }
  .list div:nth-child(2) {
    grid-area: 1 / 2 / 2 / 3;
  }
  .list div:nth-child(3) {
    grid-area: 2 / 1 / 3 / 2;
  }
  .list div:nth-child(4) {
    grid-area: 2 / 2 / 3 / 3;
  }
  .list div:nth-child(5) {
    grid-area: 3 / 1 / 4 / 2;
  }
  .list div:nth-child(6) {
    grid-area: 3 / 2 / 4 / 3;
  }
  .list div:nth-child(7) {
    grid-area: 4 / 1 / 5 / 3;
  }
  .item {
    font-size: 0.673em;
    border-radius: 6em;
  }
}

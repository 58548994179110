.container {
  margin: 3.9375em 0 3.375em 0;
}
.list {
  margin-top: 3.4375em;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(3, 1fr);
}
.item {
  position: relative;
  padding: 4.6875em;
  border-radius: 2.8125em;
  border: 0.125em solid var(--dark);
  text-align: center;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}
.item:first-child {
  grid-area: 1 / 1 / 2 / 2;
}
.item:nth-child(2) {
  grid-area: 1 / 2 / 2 / 4;
}
.item:nth-child(3) {
  grid-area: 1 / 4 / 2 / 5;
}
.item:nth-child(4) {
  grid-area: 1 / 5 / 2 / 6;
}
.item:nth-child(5) {
  grid-area: 2 / 1 / 3 / 3;
}
.item:nth-child(6) {
  grid-area: 2 / 3 / 3 / 4;
}
.item:nth-child(7) {
  grid-area: 2 / 4 / 3 / 6;
}
.item:nth-child(8) {
  grid-area: 2 / 6 / 3 / 7;
}
.item:nth-child(9) {
  grid-area: 3 / 2 / 4 / 3;
}
.item:nth-child(10) {
  grid-area: 3 / 3 / 4 / 4;
}
.item:nth-child(11) {
  grid-area: 3 / 4 / 4 / 5;
}
.item:nth-child(12) {
  grid-area: 3 / 5 / 4 / 7;
}
.image {
  width: auto;
  height: 5.1em;
}

.item:hover .dots,
.item:focus .dots {
  fill: var(--dark);
}
.item:hover,
.item:focus {
  background-color: var(--main);
  border-color: var(--main);
}
.dots {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3.6875em;
  /* width: 100%; */
  transition: all 0.3s ease-in-out;
}
.item:hover .dotsTechLevitate,
.item:focus .dotsTechLevitate {
  animation-play-state: running;
}
.dotsTechLevitate {
  animation: 0.75s dotsLevitate ease-in-out infinite;
  animation-play-state: paused;
}
.dotsTechLevitate:nth-child(2n + 1) {
  animation-delay: 0.3s;
}
.dotsTechLevitate:nth-child(3n + 1) {
  animation-delay: 0.4s;
}
.dotsTechLevitate:nth-child(4n + 1) {
  animation-delay: 0.5s;
}
.dotsTechLevitate:nth-child(5n + 1) {
  animation-delay: 0.75s;
}
.dotsTechLevitate:nth-child(6n + 1) {
  animation-delay: 0.2s;
}
.dotsTechLevitate:nth-child(7n + 1) {
  animation-delay: 0.1s;
}
.dotsTechLevitate:nth-child(8n + 1) {
  animation-delay: 0.6s;
}

@keyframes dotsLevitate {
  0% {
    opacity: 0;
    transform: translate(0, 0);
  }

  100% {
    opacity: 1;
    transform: translate(0, -0.5em);
  }
}
@media (orientation: portrait) {
  /* .list {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(8, 1fr);
    grid-column-gap: 0.15em;
    grid-row-gap: 0.15em;
  }item
  .item:first-child {
    grid-area: 4 / 2 / 5 / 3;
  }
  .item:nth-child(2) {
    grid-area: 1 / 1 / 2 / 3;
  }
  .item:nth-child(3) {
    grid-area: 2 / 1 / 3 / 2;
  }
  .item:nth-child(4) {
    grid-area: 2 / 2 / 3 / 3;
  }
  .item:nth-child(5) {
    grid-area: 3 / 1 / 4 / 3;
  }
  .item:nth-child(6) {
    grid-area: 4 / 1 / 5 / 2;
  }
  .item:nth-child(7) {
    grid-area: 5 / 1 / 6 / 3;
  }
  .item:nth-child(8) {
    grid-area: 6 / 1 / 7 / 2;
  }
  .item:nth-child(9) {
    grid-area: 6 / 2 / 7 / 3;
  }
  .item:nth-child(10) {
    grid-area: 7 / 1 / 8 / 2;
  }
  .item:nth-child(11) {
    grid-area: 7 / 2 / 8 / 3;
  }
  .item:nth-child(12) {
    grid-area: 8 / 1 / 9 / 3;
  } */
  .list {
    display: block;
  }
  .dots {
    height: auto;
    /* width: 100%; */
  }
  .smallItem {
    height: 5.5em;
  }
}

.animation {
  opacity: 0;
  transition: all 0.5s ease-in-out;
  transform: translate(var(--translate-x), var(--translate-y));
}

.visible {
  opacity: 1;
  transform: translate(0, 0);
}
@media (orientation: portrait) {
  .animation {
    transform: translate(0, 0);
  }
}

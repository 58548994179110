.btnContainer {
  position: relative;
}
.button {
  position: relative;
  padding: 0.5em 1.11em;
  border-radius: 3.125em;
  border: none;
  background: var(--main);
  /* font-family: RedHat; */
  font-size: 2em;
  width: 10.44em;
  /* font-weight: 600; */
  line-height: 125%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  cursor: pointer;
  z-index: 1;
  transition: all 0.3s ease-in-out;
}

.buttonBorderFirst,
.buttonBorderSecond,
.buttonBorderThird {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 0.5em 2em;
  border-radius: 3.125em;
  border: 0.1em solid var(--main);
  z-index: -1;
  transition: all 0.3s ease-in-out;
}

.button:hover .buttonBorderFirst {
  top: 0.3em;
}

.button:hover .buttonBorderSecond {
  top: 0.6em;
}

.button:hover .buttonBorderThird {
  top: 0.9em;
}
.colorBlack {
  background: var(--dark);
  color: var(--main);
}
.colorBlackBorder {
  border-color: var(--dark);
}
@media (orientation: portrait) {
  .button {
    width: 100%;
    font-size: 2.57em;
    padding: 0.89em 2em;
  }
}

.title {
  margin-bottom: 0.82em;
  font-size: 3.4375em;
  line-height: 130.909%;
}
.wrapper {
  position: relative;
  padding: 1.625em;
  border-radius: 2.8125em;
  background: var(--dark);
  transition: all 0.3s ease-in-out;
  width: 100%;
  height: 100%;
  /* height: 36em; */
  background-image: none;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}

.pixeledImage {
  opacity: 0;
  /* transform: translateY(100%); */
  transition: all 0.5s ease-in-out;
}
.wrapper:hover .pixeledImage {
  opacity: 1;
  /* transform: translateY(0); */
}
.imagePixeled {
  /* display: none; */
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  width: 100%;
  height: 100%;
  /* opacity: 1; */
  /* opacity: 0; */
  /* transform: translateY(100%); */
  /* transform: scale(0); */
  transition: all 1s ease-in-out;
}

.wrapper:hover .imagePixeled {
  /* display: block; */
  opacity: 1;
  /* transform: translateY(0); */
  /* transform: scale(1); */
  width: 100%;
}

.imgPix {
  width: 100%;
  height: 100%;
  image-rendering: pixelated;
}
.activeSlide {
  background: var(--main);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.list {
  position: relative;
  z-index: 1;
  display: flex;
  gap: 0.625em;
  margin-bottom: 2.125em;
}
.image {
  width: 32.5625em;
  transition: all 1s ease-in-out;
}
.button {
  position: relative;
  margin-left: auto;
  display: flex;
  align-items: center;
  padding: 0.5em 1.11em;
  border: 0.1em solid var(--main);
  border-radius: 2.61em;
  background: var(--dark);
  font-size: 1.125em;
  cursor: pointer;
}
.arrow,
.arrowNavBtnNext,
.arrowNavBtnPrev {
  width: 0.875em;
  color: var(--main);
  margin-right: 0.45em;
  animation: 0.5s arrowRight ease-in-out infinite alternate;
  animation-play-state: paused;
}
.arrowNavBtnNext,
.arrowNavBtnPrev {
  color: var(--grey);
  margin-left: 0.45em;
}
.arrowNavBtnPrev {
  rotate: 180deg;
}
.btnText {
  color: var(--main);
  /* font-family: RedHat; */
  line-height: 133.333%;
  text-transform: uppercase;
}
.button:hover .arrow,
.button:focus .arrow {
  animation-play-state: running;
}
.swiperBtns {
  display: flex;
  justify-content: flex-end;
  margin: 3.4375em;
  margin-right: 15em;
}
.btnNext,
.btnPrev {
  display: flex;
  align-items: center;
  padding: 0.5em 1.11em;
  text-align: center;
  /* font-family: RedHat; */
  font-size: 1.125em;
  line-height: 133.333%;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 2.61em;
  border: 0.11em solid var(--grey);
  color: var(--grey);
  background: transparent;
  transition: all 0.3s ease-in-out;
}
.btnPrev {
  margin-right: 0.56em;
}
.btnNext:hover .arrowNavBtnNext,
.btnNext:focus .arrowNavBtnNext,
.btnPrev:hover .arrowNavBtnPrev,
.btnPrev:focus .arrowNavBtnPrev {
  color: var(--dark);
}
.btnNext:hover,
.btnNext:focus,
.btnPrev:hover,
.btnPrev:focus {
  color: var(--dark);
  background-color: var(--main);
  border-color: var(--main);
}
.item {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
@keyframes arrowRight {
  100% {
    transform: translateX(0.2em);
  }
}
@media (orientation: portrait) {
  .list {
    font-size: 0.752em;
    margin-bottom: 4.55em;
  }
  .button {
    font-size: 1.704em;
    margin: auto;
  }
  .block {
    margin-bottom: 1.704em;
  }
  .image {
    width: 14.34em;
  }
  .swiperBtns {
    justify-content: center;
    margin: 4.26em 0 7.81em 0;
  }
  .btnNext,
  .btnPrev {
    font-size: 1.704em;
  }
  .item {
    font-size: 0.9em;
  }
  .education::before,
  .planty::before,
  .citf::before,
  .icedate::before {
    height: 27.4em;
  }
}

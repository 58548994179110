.title {
  margin-bottom: 0.82em;
  font-size: 3.4375em;
  line-height: 130%;
}

.activeSlide {
  background: var(--main);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.list {
  position: relative;
  z-index: 1;
  display: flex;
  gap: 0.625em;
  margin-bottom: 2.125em;
}

.button {
  position: relative;
  margin-left: auto;
  display: flex;
  align-items: center;
  padding: 0.5em 1.11em;
  border: 0.1em solid var(--main);
  border-radius: 2.61em;
  background: var(--dark);
  font-size: 1.125em;
  cursor: pointer;
  z-index: 1;
  bottom: -27em;
}
.arrow {
  width: 0.875em;
  color: var(--main);
  margin-right: 0.45em;
  animation: 0.5s arrowRight ease-in-out infinite alternate;
  animation-play-state: paused;
}

.btnText {
  color: var(--main);
  /* font-family: RedHat; */
  line-height: 133.333%;
  text-transform: uppercase;
}
.button:hover .arrow,
.button:focus .arrow {
  animation-play-state: running;
}

.item {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.container {
  /* perspective: 100em; */
}
.wrapperCards {
  position: relative;
  /* width: 100%;
  height: 36em; */
  /* width: 960px;
  height: 600px; */
  width: 60em;
  height: 37.5em;
  transition: all 0.3s ease-in-out;
  transform-style: preserve-3d;
}
.wrapperFront,
.wrapperBack {
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--dark);
  backface-visibility: hidden;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  padding: 1.625em;
  /* border-radius: 2.8125em; */
  border-radius: 3.1em;
}
.wrapperFront {
  background-size: 70% 100%;
}
.wrapperBack {
  /* border-radius: 3.1em; */
  /* transform: rotateY(180deg); */
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
/* .container .wrapperCards {
  transform: scale(1) rotateY(0deg);
  transition: transform 0.3s ease-in-out;
}
.container:hover .wrapperCards {
  transform: scale(0.9) rotateY(180deg);
  transition: transform 0.3s ease-in-out;
} */

.wrapperCards:hover .wrapperBack {
  opacity: 1;
}

@keyframes arrowRight {
  100% {
    transform: translateX(0.2em);
  }
}
@media (orientation: portrait) {
  .list {
    font-size: 0.752em;
    margin-bottom: 4.55em;
  }
  .button {
    font-size: 1.704em;
    bottom: -11em;
    /* margin: auto; */
  }
  .block {
    margin-bottom: 1.704em;
  }
  .image {
    width: 14.34em;
  }
  .swiperBtns {
    margin: 4.26em 10.5em 7.81em 0;
  }
  .btnNext,
  .btnPrev {
    font-size: 1.704em;
  }
  .item {
    font-size: 0.9em;
  }
  .education::before,
  .planty::before,
  .citf::before,
  .icedate::before {
    height: 27.4em;
  }
  .wrapperCards {
    width: 41.19em;
    height: 25.82em;
  }
  .title {
    font-size: 3.8375em;
  }
}

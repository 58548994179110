.container {
  display: flex;
  gap: 5.0625em;
  margin-bottom: 7.5625em;
}
.form {
  width: 50%;
}
.title {
  font-size: 4.5em;
  font-weight: 600;
  line-height: 133.333%;
}
.label {
  position: relative;
  display: block;
  margin-top: 1.5em;
}
.label:first-of-type {
  margin-top: 0;
}
.label:last-of-type {
  margin-bottom: 2.0625em;
}
.name,
.input {
  color: var(--grey);
  /* font-family: RedHat; */
  font-size: 1.125em;
  line-height: 133.333%;
}
.input {
  text-transform: uppercase;
  margin-top: 0.333em;
  width: 100%;
  padding: 0.555em 1.333em;
  border-radius: 2.67em;
  border: 0.1em solid var(--grey);
  outline: none;
  transition: all 0.3s ease-in-out;
}
.input:active,
.input:hover {
  background-color: var(--main);
}
.errorBorder {
  border-color: var(--red);
}
.errorMessage {
  position: absolute;
  right: 2em;
  top: 3em;
  color: var(--red);
  /* font-family: RedHat; */
  font-size: 0.875em;
  font-weight: 400;
  line-height: 171%;
}
.imageContainer {
  position: relative;
  width: 45em;
}
.icon {
  position: absolute;
  width: 100%;
  bottom: -5em;
  left: 8em;
  color: var(--main);
}
.imageContainer {
  border-radius: 2.8125em;
  border: 0.125em solid var(--dark);
  overflow: hidden;
}
.checkbox {
  display: none;
  opacity: 0;
}
@media (orientation: portrait) {
  .container {
    display: flex;
    flex-direction: column;
    gap: 8.0625em;
  }
  .form {
    width: auto;
  }
  .title {
    font-size: 3.41em;
    font-weight: 500;
  }
  .label {
    margin-top: 3.41em;
  }
  .label:first-of-type {
    margin-top: 2.84em;
  }
  .label:last-of-type {
    margin-bottom: 5.113em;
  }
  .input {
    font-size: 2.56em;
  }
  .name {
    font-size: 1.7em;
  }
  .errorMessage {
    font-size: 1.7em;
  }
  .imageContainer {
    display: none;
    /* width: 40.48em;
    height: 40.48em;
    border-radius: 6.39em; */
  }
  .icon {
    bottom: -2em;
    left: 11em;
  }
}

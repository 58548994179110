.header {
  padding: 3.125em 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.logo {
  font-size: 2.25em;
  font-weight: 800;
  letter-spacing: 0.01em;
  display: flex;
  align-items: center;
}
.logo a {
  color: inherit;
}
.logoIcon {
  width: 1.16em;
  margin-right: 0.33em;
}
.menu {
  display: none;
  align-items: center;
  justify-content: center;
  padding: 0.27em 1.111em;
  margin-left: 2.67em;
  border: 0.1em solid var(--dark);
  border-radius: 2.61em;
  /* font-family: RedHat; */
  font-size: 1.125em;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  max-width: 100%;
  overflow: hidden;
}
.dotMenu {
  fill: var(--dark);
  width: 0.222em;
  margin-right: 0.556em;
}
/* .menu:hover .dot,
.menu:focus .dot {
  animation-play-state: paused;
} */
.dots {
  display: flex;
  margin-bottom: 0.4em;
  max-width: 100%;
  overflow: hidden;
  transition: max-width 0.3s ease-in-out;
}
.widthDecrease {
  max-width: 3em;
}
/* .dot {
  margin-right: 0.556em;
  font-weight: 800;
  animation: 0.5s dotsMove ease-in-out infinite alternate;
}
.dot:nth-child(2) {
  animation-delay: 0.2s;
}
.dot:nth-child(3) {
  animation-delay: 0.4s;
} */

/* .text {
  display: inline-block;
  transition: all 0.3s ease-in-out;
  opacity: 1;
}
.showText {
  opacity: 1;
  max-width: 100%;
  transform: translateX(0);
  transition: 1s ease-in-out;
}
.hideText {
  opacity: 0;
  max-width: 0;
  transform: translateX(-200%);
  transition: 1s ease-in-out;
} */
.showText-entering,
.showText-exiting,
.hideText-entering,
.hideText-exiting {
  position: absolute;
}

.showTextEntering,
.hideTextExiting {
  opacity: 0;
  transform: translateX(-200%);
}

.showTextEntered,
.hideTextExited {
  opacity: 1;
  transform: translateX(0);
  max-width: 100%;
}
.showTextExiting,
.hideTextEntering {
  opacity: 1;
  transform: translateX(0);
  max-width: 100%;
}

.text {
  display: inline-block;
  transition: all 0.3s ease-in-out;
  position: relative;
}
.list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-left: 4em;
}
.item {
  position: relative;
  text-align: center;
  /* font-family: RedHat; */
  font-size: 1.125em;
  color: var(--dark);
  line-height: 133.333%;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
/* .item:not(:last-child) {
  margin-right: 3em;
}
.item:last-child {
  margin-left: 5em;
} */
.item::before {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  color: var(--main);
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  pointer-events: none;
}
.item:hover::before {
  height: 100%;
}

.item a {
  color: inherit;
}
.lang {
  /* padding: 0.5em; */
  padding: 0.5em 0.61em;
  border: 0.11em solid var(--dark);
  /* border-radius: 50%; */
  border-radius: 1.1em;
  text-align: center;
  /* font-family: RedHat; */
  font-size: 1.125em;
  line-height: 133.333%;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
}
.lang:hover,
.lang:focus {
  background: var(--main);
}
.languageList {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  gap: 0.2em;
  transition: all 0.3s ease-in-out;
  /* z-index: -1; */
}
.showLanguageList {
  opacity: 1;
  transform: translateY(3em);
  pointer-events: initial;
}
.hide {
  display: none;
}
@keyframes dotsMove {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(0, -0.1em);
  }
}

@media (orientation: portrait) {
  .menu {
    display: flex;
  }
  .list,
  .lang,
  .logoIcon {
    display: none;
  }
  .logo {
    font-size: 3.41em;
  }
  .menu {
    font-size: 2.556em;
  }
}

.overvlow {
  overflow: hidden;
}

.modalBackDrop {
  position: fixed;
  display: flex;
  /* margin: 0 1.71em; */
  top: 10em;
  left: 0;
  width: 100vw;
  height: 100dvh;
  padding: 2em 1.71em;
  background: var(--white);
  overflow: auto;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}
.opacity {
  visibility: visible;
  opacity: 1;
}
.container {
  position: relative;
  transform: translateY(-100vh);
  transition: all 0.5s ease-in-out;
}
.containerActive {
  transform: translateY(0);
}
.item,
.lang {
  color: var(--dark);
  /* font-family: RedHat; */
  font-size: 18px;
  line-height: 133.333%;
  text-transform: uppercase;
  position: relative;
}
.item a {
  color: inherit;
}
.item:not(:first-child) {
  margin-top: 1.33em;
}
.lang {
  margin-top: 2.444em;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.17em;
  height: 3.17em;
  /* padding: 0.5em; */
  padding: 0.5em 0.61em;
  border: 0.11em solid var(--dark);
  /* border-radius: 50%; */
  border-radius: 1.3em;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
}
.socialsContainer {
  position: absolute;
  bottom: 15em;
}
.socialList {
  display: flex;
}
.socialsItem {
  width: 5.966em;
  height: 5.966em;
  padding: 0.5625em;
  border: 0.11em solid var(--dark);
  border-radius: 50%;
  cursor: pointer;
}

.socialsItem:not(:last-child) {
  margin-right: 0.425em;
}
.iconSocial {
  color: var(--dark);
}
.subTitleSocials {
  margin-bottom: 0.816em;
  color: var(--grey);
  /* font-family: RedHat; */
  font-size: 1.846em;
  font-weight: 400;
  line-height: 138.462%;
  letter-spacing: 0.02em;
}
.languageList {
  display: flex;
  /* flex-direction: column; */
  gap: 0.5em;
  transition: all 0.3s ease-in-out;
}
.lang:hover,
.lang:focus {
  background-color: var(--main);
}
